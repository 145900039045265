:root {
    --primary: rgba(0, 0, 0, 0);
    --secondary: rgb(25, 40, 34);

    --secondary-light: rgb(104, 129, 119);

    --contrast-primary: #fff;
    --contrast-secondary: #fff;
    --contrast-accent: #fff;

    color: white;
}

@font-face {
    font-family: "Glockenspiel";
    src: url('./assets/LT\ Glockenspiel\ Black.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

button, input[type="submit"] {
    cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
