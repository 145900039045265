.secretariat {
    height: 100%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-bottom: 10vh;
}

.image-placeholder {
    width: 100%;
    aspect-ratio: 16/9;
}

.image-placeholder-2 {
    width: 100%;
    aspect-ratio: 1/1;
}

.oc {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(4, 1fr);

    grid-row-gap: 5vh;
    /* grid-column-gap: 5vh; */

    & h1 {
        font-family: "Glockenspiel", serif !important;
        letter-spacing: 0.075vw;
    }

    & > div {
        height: fit-content;
    }
}

.double-oc-card {
    grid-column: span 4;

    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: center;

    backdrop-filter: blur(10px);
    border: 1px solid white;
    border-radius: 10px;

    height: fit-content;

    & > .double-oc-information {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    & > .double-oc-information > div {
        width: 50%;
    }

    & > .double-oc-information > div > .image-placeholder-2 {
        width: 95%;
        margin: 0 2.5%;
    }
}

.image-placeholder-2 {
    object-fit: cover;
}

.oc-card {
    align-items: center;
    text-align: center;

    backdrop-filter: blur(10px);
    border: 1px solid white;
    border-radius: 10px;

    height: fit-content;

    position: relative;

    & > .image-placeholder-2 {
        width: 95%;
        margin: 0 2.5%;
        object-fit: cover;
    }

    &:not(:first-child) {
        grid-column: span 2;
        width: 95%;
        height: 100%;
    }

    &:first-child {
        grid-column: 2 / 4;
    }

    &:nth-child(4), &:nth-child(5), &:last-child {
        & > h1 {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            text-align: center;
            font-size: 4vh;
            line-height: 4vh;
        }

        & > img {
            position: absolute;

            top: 12vh;
            left: 0;
        }

        & > p {
            position: absolute;

            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            text-align: center;
        }
    }

    &:nth-child(4) {
        margin-right: 5%;
    }

    &:nth-child(5) {
        margin-left: 5%;
    }

    &:last-child {
        left: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .secretariat {
        width: 90%;
    }

    .oc {
        grid-column-gap: 1.25vh;

        & > div:not(:first-child) {
            margin-top: -2vh;
        }
    }

    .oc-card h1 {
        font-size: 2.5vh !important;
    }

    .oc-card:nth-child(4), .oc-card:nth-child(5), .oc-card:nth-child(6) {
        height: 100%;

        & > img {
            top: 11vh;
        }
    }
}