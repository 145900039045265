.form-page {
    width: 50vw;
    height: auto;

    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 10vh;

    & > h1 {
        font-family: "Glockenspiel", serif !important;
        text-align: center;
        font-size: 10vh;
        line-height: 10vh;
    }
}

form {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    & > * { margin-bottom: 1vh; }
}

input, select, textarea {
    background-color: var(--secondary-light);
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 0.5em;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    box-sizing: border-box;
    height: fit-content;
}

input {
    padding-left: 0.5vw;

    &::placeholder { color: #ffffffbf; }
}

input:not(#confirmation), select { height: 4vh }

label[for=confirmation] {
    font-size: 2vh !important;
}

select {
    padding-left: 0.25vw;
    color: #ffffffbf;
}

textarea {
    resize: vertical;
    padding-left: 0.5vw;

    &::placeholder { color: #ffffffbf; }
}

.select-placeholder {
    color: #ffffffbf;
    background-color: var(--secondary);
}

.add-sub-delegate {
    border: none;
    border-radius: 100%;
    width: 5vh;
    height: 5vh;
    font-size: 5vh;
    line-height: 5vh;
    background-color: #0A5C36;
    position: relative;
    margin-left: auto;

    &:hover:before {
        position: absolute;
        content: "Add Delegate";
        display: inline-block;
        background-color: #0A5C36BA;
        color: white;
        font-size: 1.5vh;
        line-height: 2vh;
        margin-left: -12vh;
        margin-top: auto;
        margin-bottom: auto;
        width: max-content;
        height: 2vh;
        margin-top: 1.25vh;
        text-align: center;
        justify-content: center;
        padding: 0.25vh;
        border-radius: 5px;
    }
}

.update {
    background-color: var(--secondary-light);
    border: none;
    color: white;
    border-radius: 0.5em;
    padding: 2%;
}

#submit {
    background-color: #0A5C36;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh 1vw 1vh 1vw;
}

#allotment-preferences {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    box-sizing: border-box;

    & > * {
        width: 98%;
    }
}

#double-allotment-preferences {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 1vh;
}

#primary-comm, #secondary-comm {
    margin-bottom: 1vh;
}

#secondary-portfolio-input2, #secondary-portfolio-input, #secondary-comm {
    margin-left: auto;
}

.payment-details {
    font-size: 2.5vh;
}

.double-info {
    display: none;
    flex-direction: column;
    border-top: 1px solid white;

    & > * {
        margin-bottom: 1vh;
        width: 100%;
    }
}

.shown {
    display: flex;
}

.sub-delegate-form {
    width: 100%;
    border: 1px white solid;
    border-radius: 20px;
    padding-top: 1vh;
    margin-top: 3vh;

    & > * {
        width: 99%;
        margin-left: 0.5%;
    }
}

.error {
    color: red;
}

.success {
    color: #0A5C36;
}

#status {
    text-align: center;
}

.instructions {
    background-color: #00ff0088;
    border-radius: 1em;
    border: 2px solid #00ff00;

    padding: 10px;
}

@keyframes pulsate {
    from, to { background-color: #0A5C36; }
    50% { background-color: red; }
}

.pulse {
    animation: pulsate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@media screen and (max-width: 1200px) {
    .form-page {
        width: 100%;

        & > h1 {
            font-size: 7.5vh;
            line-height: 7.5vh;
        }
    }

    form {
        width: 90%;
    }

    #submit {
        width: 50%;
    }
}