.registration-content {
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-bottom: 5vh;
}

.registration-header {
    font-family: "Glockenspiel", serif !important;
    font-size: 10vh;
    text-align: center;
    color: white;
}

.registration-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2vw;

    & a {
        text-decoration: none;
        color: white;
    }
}

.option {
    background-color: transparent;
    backdrop-filter: blur(10px);
    border: 0.2em solid var(--secondary-light);
    border-radius: 1em;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    &:hover { text-decoration: underline; }
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
    & > img {
        width: 100%;
        filter: invert(1) opacity(0.5);
        object-fit: scale-down;
    }
}

@media screen and (max-width: 1200px) {
    .registration-content {
        width: 90%;
    }

    .option p {
        font-size: 1.5vh;
    }

    .registration-header {
        font-size: 7vh;
    }
}