.delegate-resources-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
}

.subhead {
    border-bottom: 1px solid #ffffffba;
    margin-top: 7.5vh;
}

.resources {
    width: 100%;

    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(8, 1fr);

    transform-style: preserve-3d;
    transform: perspective(1000px);

    &>div {
        width: 100%;
        aspect-ratio: 1/1;
        background-color: transparent;
        backdrop-filter: blur(10px);
        border: 1px solid white;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        transition-timing-function: ease-in-out;

        &:hover {
            transform: translateZ(200px);
            background-color: rgb(25, 40, 34);
        }
    }

    &>div>h1 {
        font-size: 1.5em;
    }

    & > div > a {
        width: 75%;
        font-size: 0.75em;
        height: 2em;
        margin-top: 2.5%;
        margin-bottom: 2.5%;
        border: 1px solid white;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: white;
        text-decoration: none;
    }
}

.resources>div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;

    &:hover {
        box-shadow: #ffffffba 0.75vw 0.25vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(2) {
    grid-area: 1 / 3 / 2 / 5;

    &:hover {
        box-shadow: #ffffffba 0.25vw 0.5vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(3) {
    grid-area: 1 / 5 / 2 / 7;

    &:hover {
        box-shadow: #ffffffba -0.25vw 0.5vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(4) {
    grid-area: 1 / 7 / 2 / 9;

    &:hover {
        box-shadow: #ffffffba -0.75vw 0.25vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(5) {
    grid-area: 2 / 2 / 3 / 4;

    &:hover {
        box-shadow: #ffffffba 0.25vw -0.25vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(6) {
    grid-area: 2 / 4 / 3 /6;

    &:hover {
        box-shadow: #ffffffba 0vw -0.5vw 0.5vw 0.1vw;
    }
}

.resources>div:nth-child(7) {
    grid-area: 2 / 6 / 3 / 8;

    &:hover {
        box-shadow: #ffffffba -0.25vw -0.25vw 0.5vw 0.1vw;
    }
}

.conf-info {
    display: flex;
    grid-column-gap: 1vw;

    transform-style: preserve-3d;
    transform: perspective(1000px);

    position: relative;

    & > a {
        backdrop-filter: blur(10px);
        border: 1px white solid;
        border-radius: 1rem;

        color: white;
        text-decoration: none;

        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        font-family: "Glockenspiel", serif !important;
        font-size: 5vh;
        transition-duration: 0.5s;
        /* transition-property: flex; */
    }
}

@media screen and (max-width: 1200px) {
    .delegate-resources-container {
        width: 90%;
    }

    .resources {
        display: block;
    }

    .resources > div:hover, .resources > div:focus {
        transform: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }

    .conf-info > a {
        font-size: 5vh;
        text-align: center;
    }

    .conf-info > a::after {
        font-size: 2.5vh;
    }
}