.not-found-content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    & > h1 {
        margin: 0;
        font-size: 50vh;
        text-align: center;
    }

    & > p {
        text-align: center;
        font-size: 2rem;
    }
}

@media screen and (max-width: 1200px) {
    .not-found-content {
        width: 90%;

        & > h1 {
            font-size: 25vh;
        }

        & > p {
            font-size: 1.5rem;
        }
    }
}