* {
    font-family: "Times New Roman", serif;
}

body {
    background-image: url('../assets/bg.webp');
    background-attachment: fixed;
}

@keyframes title-anim {
    from {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, -25%, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

.title {
    margin: 0;
    text-align: center;
    color: white;

    & > div {
        margin-left: auto;
        margin-right: auto;
    }

    & > p {
        margin: 0;
        margin-bottom: 5vh;
        font-size: 1.6em;
    }
}

.header {
    display: flex;
    justify-content: center;

    & > h1 {
        font-size: 15vw;
        line-height: 15vw;
        margin: 0;

        font-family: "Glockenspiel", serif !important;
        background-image: url('../assets/marble.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        animation: title-anim .5s normal forwards ease-in-out;
    }

    & > h1:nth-child(1) { animation-delay: 1000ms; }
    & > h1:nth-child(2) { animation-delay: 1100ms; }
    & > h1:nth-child(3) { animation-delay: 1200ms; }
    & > h1:nth-child(4) { animation-delay: 1300ms; }
    & > h1:nth-child(5) { animation-delay: 1400ms; }
    & > h1:nth-child(6) { animation-delay: 1500ms; }
    & > h1:nth-child(7) { animation-delay: 1600ms; }
    & > h1:nth-child(8) { animation-delay: 1700ms; }
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rotary-logo {
    top: 10vh;
    left: 2.5vh;
    position: absolute;
    box-sizing: border-box;

}

.flags {
    width: 100%;
    height: 500px;
    background-image: url('../assets/flags.jpg');
    background-size: cover;
}

.about-div {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 2%;

    & > p {
        font-size: 2vh;
    }
}

.about-heading {
    display: flex;
    padding-top: 1vh;

    & > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    & h2 {
        font-family: "Glockenspiel", serif !important;
        line-height: 3vh;
        letter-spacing: 0.075vh;
        font-size: 3vh;
        margin: 0;
    }

    & h1 {
        font-family: "Glockenspiel", serif !important;
        letter-spacing: 0.075vh;
        line-height: 5vh;
        font-size: 5vh;
        margin: 0;
    }
}

.about {
    background-image: url("../assets/stacked-waves-haikei.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150%;
    color: #fff;
}

.countdown {
    padding-top: 1px;
    background-attachment: fixed;
    text-align: center;
    color: white;

    & > h1 {
        font-size: 10vh;
        line-height: 10vh;
        margin-bottom: 0;

        font-family: "Glockenspiel", serif !important;
        letter-spacing: 0.075vw;
    }

    & > p {
        font-size: 2vh;
    }
}

#date {
    margin: 0;
    padding-bottom: 5vh;

    & > div > h2 {
        font-size: 6vh;
        line-height: 6vh;
        margin-bottom: 0;
    }

    & > div:not(:last-child) { padding-right: 2vw; }
}

#principal-letter-section, #oc-letter-section {
    background-color: transparent;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

#image-placeholder-3 {
    height: 45vh;
    aspect-ratio: 2/3;
    object-fit: cover;
}

#principal-letter, #oc-letter {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;

    & > h1 {
        font-family: "Glockenspiel", serif !important;
        margin: 0;
        padding-bottom: 2vh;
        font-size: 7vh;
        text-align: center;
    }
}

#principal-letter-content, #oc-letter-content {
    display: flex;
    flex-direction: row;
}

#letter, #actual-oc-letter {
    width: 100%;
    font-size: 2vh;
}

#letter { margin-left: 2vw; }
#actual-oc-letter { margin-right: 2vw; }

/* Compatability VVVVVVVVV */

@media screen and (max-width: 1200px) {
    .title {
        height: 100%;
    }

    .about-div {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 5vh;

    }

    .about-heading {
        & > h1 {
            font-size: 4vh;
            line-height: 4vh;
        }

        & > h2 {
            font-size: 3vh;
            line-height: 3vh;
        }
    }

    .countdown h1 {
        font-size: 7vh;
        line-height: 7vh;
    }

    .about p {
        font-size: 2vh;
        /* line-height: 2vh; */
    }

    .flags {
        background-size: cover;
        width: 100%;
        height: 200px;
        background-position-x: 40%;
    }

    #principal-letter-content {
        flex-direction: column;
    }

    #oc-letter-content {
        flex-direction: column-reverse;
    }

    #image-placeholder-3 {
        height: 25vh;
        margin-left: auto;
        margin-right: auto;
    }

    #principal-letter, #oc-letter {
        width: 95%;
        height: auto;

        & > h1 {
            font-size: 4vh;
            text-align: left;
        }
        & > p {
            font-size: 1.5vh;
            line-height: 1.5vh;
        }
    }

    #letter { margin-left: 0; }
    #actual-oc-letter { margin-right: 0; }

    .logo-container {
        height: 15vh;

        & > * {
            height: 100% !important;
        }
    }
    .rotary-logo {
        max-width: 25%;
        min-width: 25%;
        height: auto !important;
    }
}
