.login-page {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}

.post-type {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.thank-you-page {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 5vh;
}

.login-submit {
    cursor: pointer;
}